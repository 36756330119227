// import InputArray from "../InputArray/InputArray";
// import YesNoSelectorContainer from "../../container/YesNoSelectorContainer/YesNoSelectorContainer";
// import RatingContainer from "../../container/RatingContainer/RatingContainer";
// import CheckBoxContainer from "../../container/CheckBoxContainer/CheckBoxContainer";
// import SelectContainer from "../../container/SelectContainer/SelectContainer";
// import InputContainer from "../../container/InputContainer/InputContainer";
// import ScaleContainer from "../../container/ScaleContainer/ScaleContainer";
import { useEffect, useState } from "react";
import ShowIfHOC from "../../HOC/ShowIfHOC/ShowIfHOC";
import ContainerSelector from "../ContainerSelector/ContainerSelector";

interface ComponentSelectorProps {
  response: any;
  control?: any;
  register?: any;
  errors?: any;
  unregister?: any;
  getValues?: any;
  reset?: any;
  setValue?: any;
  watch?:any;
}

const ComponentSelector = ({
  response,
  control,
  register,
  errors,
  unregister,
  getValues,
  reset,
  setValue,
  watch
}: ComponentSelectorProps) => {
  const { showIf } = response;

  //condition for HOC component isShowIf available or not
  const showHOC = showIf.length !== 0;

  //render component on the basis of condition
  if (showHOC) {
    return (
      // if the showIF is available in the form data then it would be called
      <ShowIfHOC
        watch={watch}
        response={response}
        control={control}
        register={register}
        errors={errors}
        unregister={unregister}
        getValues={getValues}
        reset={reset}
        setValue={setValue}
      />
    );
  } else {
    return (
      // showif length is not then choose container directly
      <ContainerSelector
        response={response}
        control={control}
        register={register}
        errors={errors}
        unregister={unregister}
        getValues={getValues}
        reset={reset}
        setValue={setValue}
      />
    );
  }
};

export default ComponentSelector;
