import ContainerSelector from "../../components/ContainerSelector/ContainerSelector";

interface ShowIfHOCProps{
    response: any;
    control: any;
    register: any;
    errors: any;
    unregister: any;
    getValues: any;
    reset:any;
    setValue:any;
    watch?:any;
}

const ShowIfHOC = ({
    response,
    register,
    unregister,
    errors,
    control,
    getValues,
    reset,
    setValue,
    watch
  }:ShowIfHOCProps) => {

    const {showIf,id} = response;

    console.log("watch ins showIfHOC",watch());

    console.log(showIf,id,"0000")

  return (
      <>
      <ContainerSelector
        hide={true}
        response={response}
        control={control}
        register={register}
        errors={errors}
        unregister={unregister}
        getValues={getValues}
        reset={reset}
        setValue={setValue}
      />
      </>
  )
}

export default ShowIfHOC